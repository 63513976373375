<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      class="back-button"
      small
      color="red darken-3"
      @click="$router.push({ name: 'Digi-Boards' })"
    >
      <v-icon left>
        mdi-chevron-double-left
      </v-icon>
      Back
    </v-btn>
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getDbsDigiBoardTimeSlots()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-clipboard-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Time Slots
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getBoardSpecificationDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-monitor-cellphone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Specifications
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-monitor-dashboard
                </v-icon>
              </v-list-item-icon>
              Device Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getDbsDigiBoardTimeSlots()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-clipboard-clock-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Time Slots
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getBoardSpecificationDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-monitor-cellphone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Specifications
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <digi-board-time-slots-list v-if="showTimeSlots" />
      <digi-board-specifications v-if="showBoardSpecifications" />
    </div>
  </v-container>
</template>

<script>
import DigiBoardTimeSlotsList from './DigiBoardTimeSlotsList.vue';
import DigiBoardSpecifications from './DigiBoardSpecifications.vue';
import global from 'src/mixins/global';

 export default {
    name: 'DigiBoardDetailsLandingPage',
    components: {
    'digi-board-time-slots-list': DigiBoardTimeSlotsList,
    'digi-board-specifications': DigiBoardSpecifications,
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showBoardSpecifications: false,
      showTimeSlots: true,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getBoardSpecificationDetails () {
        this.showTimeSlots = false;
        this.showBoardSpecifications = true;
      },
      getDbsDigiBoardTimeSlots () {
       this.showBoardSpecifications = false;
       this.showTimeSlots = true;
    },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.back-button {
  margin-top: 20px;
  margin-left: 50px;
}
</style>
