<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <div v-if="isSpecificationFetched">
      <v-row>
        <v-col
          cols="12"
          md="9"
        >
          <h2 class="title-style">
            <v-icon class="icon-style">
              mdi-monitor-cellphone
            </v-icon> Device Specifications
          </h2>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="device-details"
        >
          <p class="title-name">
            Device Id:-
            <font class="device-id">
              {{ digiBoardDetails.external_id }}
            </font>
          </p> <p class="title-name">
            Location:-
            <font
              class="location-name"
            >
              {{ digiBoardDetails.location }}
            </font>
          </p>
        </v-col>
      </v-row>
      <v-card
        class="my-base-vcard-style"
      >
        <br>
        <v-card
          class="my-vcard-style"
        >
          <br>
          <br>
          <br>
          <v-container
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="my-col-style"
              >
                <div class="specs">
                  <p class="my-p-style">
                    Name:-
                    <font
                      class="font-my-style"
                    >
                      {{ digiBoardDetails.digi_board_specification.name | firstLetterCapital }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Display Type:-
                    <font class="font-my-style">
                      {{ digiBoardDetails.digi_board_specification.display_type.toUpperCase() }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Dimension:-
                    <font class="font-my-style">
                      {{ digiBoardDetails.height }} * {{ digiBoardDetails.width }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Device Position:-
                    <font class="font-my-style">
                      {{ digiBoardDetails.digi_board_specification.position | firstLetterCapital }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Placement:-
                    <font class="font-my-style">
                      {{ digiBoardDetails.digi_board_specification.placement | firstLetterCapital }}
                    </font>
                  </p>
                  <div class="py-3" />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-container fluid>
                  <div
                    id="board-div"
                    class="pa-4 text-center"
                  >
                    <v-alert
                      v-if="!permissionCheck('get-board-contents')"
                      text
                      prominent
                      type="warning"
                      color="red"
                      border="left"
                      class="authorization-alert"
                    >
                      You are not authorized to perform this action. Please contact your administrator.
                    </v-alert>
                    <div
                      v-if="!isBoardImagesAvailable"
                      class="no-file-alert"
                    >
                      <h4>
                        No Images Found.
                      </h4>
                    </div>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <template v-for="(img, n) in boardImages">
                        <v-col
                          :key="n"
                          cols="12"
                          sm="3"
                          md="3"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-card
                              :elevation="hover ? 12 : 6"
                              class="mx-auto"
                              :class="{ 'on-hover': hover }"
                              color="#CFD8DC"
                              style="border:1px solid #546E7A;"
                            >
                              <div v-if="isContentImgOrGif(img.content_type)">
                                <v-img
                                  :src="getFilePath(img.file_path)"
                                  :lazy-src="getFilePath(img.file_path)"
                                  aspect-ratio="1"
                                  width="100%"
                                  height="180"
                                  class="my-imag-style"
                                  contain
                                >
                                  <template #placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      />
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>
                              <div v-else>
                                <video
                                  width="100%"
                                  height="180"
                                  controls
                                >
                                  <source
                                    :src="getFilePath(img.file_path)"
                                    :lazy-src="getFilePath(img.file_path)"
                                    type="video/mp4"
                                  >
                                </video>
                              </div>
                              <v-fade-transition>
                                <v-overlay
                                  v-if="hover"
                                  absolute
                                  color="#036358"
                                >
                                  <v-col v-if="isContentImgOrGif(img.content_type)">
                                    <v-icon
                                      large
                                      @click="showImg(img.file_path)"
                                    >
                                      mdi-image
                                    </v-icon>
                                  </v-col>
                                  <v-col v-if="!isContentImgOrGif(img.content_type)">
                                    <v-icon
                                      large
                                      @click="showVideo(img.file_path)"
                                    >
                                      mdi-video
                                    </v-icon>
                                  </v-col>
                                </v-overlay>
                              </v-fade-transition>
                              <br>
                              <div class="file-details">
                                <p class="my-p-style">
                                  File Name:-
                                  <font
                                    class="font-my-style"
                                  >
                                    {{ img.original_file_name }}
                                  </font>
                                </p>
                              </div>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </template>
                    </v-row>
                  </div>
                </v-container>
                <div class="py-3" />
              </v-col>
            </v-row>
          </v-container>
          <image-preview
            v-if="showPreview"
            :url="selectedLink"
            @closed="showPreview = false"
          />
          <video-preview
            v-if="showVideoPreview"
            :url="selectedVideoLink"
            @closed="showVideoPreview = false"
          />
          <centre-spinner
            :loading="loading"
          />
        </v-card>
        <br>
      </v-card>
    </div>
  </v-container>
</template>
<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import { cdnUrl } from 'src/app-globals';
 import global from 'src/mixins/global';
 import ImagePreview from 'src/views/dashboard/component/ImagePreview';
import VideoPreview from 'src/views/dashboard/component/VideoPreview';

 export default {
   name: 'DigiBoardSpecifications',
   components: {
     'centre-spinner': spinner,
     'image-preview': ImagePreview,
      'video-preview': VideoPreview,
   },
    filters: {
      firstLetterCapital (val) {
        if (val !== undefined) {
          return val[0].toUpperCase() + val.slice(1);
        }
      },
    },
  mixins: [global],
  data () {
    return {
      loading: false,
      showPreview: false,
      selectedLink: null,
      showVideoPreview: false,
      selectedVideoLink: null,
    };
  },
    computed: {
      orgDigiBoardList () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      digiBoardDetails () {
        const digiBoardDetails = this.orgDigiBoardList;
        const deviceExternalId = this.$route.params.external_id;
        const filteredDigiBoardDetails = digiBoardDetails.filter(i => i.external_id === deviceExternalId);
        return filteredDigiBoardDetails[0];
      },
      isSpecificationFetched () {
        if (this.orgDigiBoardList.length > 0 && Object.keys(this.digiBoardDetails).length > 0) {
          return true;
        }
        return false;
      },
      boardImages () {
        return this.$store.getters['digiboards/getBoardImages'];
      },
      isBoardImagesAvailable () {
        if (this.boardImages.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-board-list') && await this.fetchDigiBoardDetails();
      this.permissionCheck('get-board-contents') && await this.getBoardImages();
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    methods: {
      async fetchDigiBoardDetails () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchDigiBoards');
        this.loading = false;
      },
      async getBoardImages () {
        this.loading = true;
        const digiBoardId = this.$route.params.external_id;
        await this.$store.dispatch('digiboards/fetchBoardImages', {
          params: {
            categoryType: 'digi_board',
            digiBoardId: digiBoardId,
          },
        }).then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      showImg (link) {
        this.selectedLink = `${cdnUrl}/${link}`;
        this.showPreview = true;
      },
      showVideo (link) {
        this.selectedVideoLink = `${cdnUrl}/${link}`;
        this.showVideoPreview = true;
      },
    },
 };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.font-my-style {
 font-weight: bold;
 font-size: 16px;
 color: #37474F;
}
.my-p-style {
 font-size: 17px;
 font-weight: bold;
 color: #546E7A;
 font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#board-div {
 background-color: #CFD8DC;
 margin: auto;
}
.stand-div {
 height: 60px;
 width: 10px;
 background-color: #CFD8DC;
 margin: auto;
}
.blink-image {
 position: absolute;
 margin: auto;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 animation: blink 1s step-start infinite;
}
@keyframes blink {
 50% {
   opacity: 0;
 }
}
.message-style {
  font-size: 20px;
  font-weight: bold;
  color: #37474F;
  font-family:'Times New Roman', Times, serif;
  text-align: center;
}
.my-col-style {
  padding-left: 40px !important;
  padding-top: 50px !important;
}
@media (max-width: 767px) {
 .my-col-style {
  padding-left: 24px !important;
}
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.device-id {
  font-weight: bold;
  font-size: 17px;
  color: #FF3700;
}
.title-name {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-bottom: 5px;
}
.location-name {
  font-weight: bold;
  font-size: 17px;
  color: #37474F;
}
.device-details {
  border: 2px solid #CFD8DC;
  border-radius: 15px;
}
.specs {
  border-left: 5px solid #CFD8DC;
  padding-left: 20px;
}
.no-file-alert {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 100px;
  margin-top: 100px;
}
.my-imag-style {
  background-color: #CFD8DC !important;
}
</style>
