<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-text-clock-outline
      </v-icon> Device Time Slots
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="digiBoardTimeSlots"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.duration }} Sec</td>
            <td>
              <font
                :color="getColor(item.purchase_status)"
                class="font-my-style"
              >
                {{ item.purchase_status | statusUpdate }}
              </font>
            </td>
            <td>
              <font>
                {{ item.created_at | convertToLocal }}
              </font>
            </td>
            <td>
              <font>
                {{ item.updated_at | convertToLocal }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="digiBoardTimeSlotPrices(item.purchase_status, item.digi_board_time_slot_id)"
              >
                <v-icon>mdi-cart-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import Constants from 'src/constants';

  export default {
    name: 'DigiBoardTimeSlotsList',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (status) {
        if (status === 'sold') {
          return 'Sold*';
        }
        return 'Available*';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Duration', value: 'duration' },
          { text: 'Purchase Status', value: 'purchase_status' },
          { text: 'Added On', value: 'created_at' },
          { text: 'Last Updated', value: 'board_updated_at' },
          { text: 'Purchase', value: 'purchase' },
        ],
        selectedOrganizationId: '',
      };
    },
    computed: {
      digiBoardTimeSlots () {
        const digiboardTimeSlotsList = this.$store.getters['digiboards/getDigiBoardTimeSlots'];
        if (digiboardTimeSlotsList.length > 0) {
          const timeSlotsList = digiboardTimeSlotsList[0].time_slots;
          const sortedList = timeSlotsList.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        return sortedList;
        }
        return [];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('view-digi-board-time-slots') && await this.fetchDigiBoardTimeSlots();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardTimeSlots () {
        const digiBoardExternalId = this.$route.params.external_id;
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchDigiBoardTimeSlots', {
          digiBoardExternalId: digiBoardExternalId,
        });
        this.loading = false;
      },
      digiBoardTimeSlotPrices (purchaseStatus, timeSlotId) {
        if (this.permissionCheck('read-time-slot-prices') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (purchaseStatus !== 'free') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Sorry, This time slot has already been taken.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        const digiBoardExternalId = this.$route.params.external_id;
        this.$router.push(`/digi-board/${digiBoardExternalId}/time-slots/${timeSlotId}/products`);
      },
      getColor (status) {
        if (status === 'free') {
          return '#2E7D32';
        };
        return '#C62828';
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }

.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
</style>
